import { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from './socket';
import axiosInstance from "../utils/axios"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogHeader } from '../components/ui/alert-dialog';
import { Button } from '../components/ui/button';


const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatContextProvider = ({ children }) => {

  const navigate = useNavigate()
  const { socket } = useSocket();
  const [isConnected, setIsConnected] = useState(false)
  const [activeChat, setActiveChatValue] = useState({})
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [chats, setChats] = useState([])

  // states for ui components
  const [showDeleteChatDialog, setShowDeleteChatDialog] = useState(false)
  const [showLeaveChatDialog, setShowLeaveChatDialog] = useState(false)
  const [chatIdToDelete, setChatIdToDelete] = useState({})
  const [chatIdToLeave, setChatIdToLeave] = useState({})

  const deleteChat = (chatId) => {
    setShowDeleteChatDialog(true)
    setChatIdToDelete(chatId)
  }

  const leaveChat = (chatId) => {
    setShowLeaveChatDialog(true)
    setChatIdToLeave(chatId)
  }
 
  const setActiveChat = useCallback((chatId) => {
    const chat = chats.find(chat => chat._id === chatId)
    setActiveChatValue(chat)
  }, [chats])

  // get messages on active chat change
  useEffect(() => {
    if (!activeChat?._id) setMessages([])
    else
      axiosInstance.get(`/message/${activeChat._id}`)
        .then((res) => {
          setMessages(res.data.data)
        }).catch((err) => {
          console.log("getMessaged() failed: ", err)
        })
  }, [activeChat, setMessages])
 

  // setup socket events 
  useEffect(() => {
    if (!socket) return

    const connectHandler = () => {
      console.log("socket connected")
      setIsConnected(true)
    }

    const disconnectHandler = () => {
      console.log("socket disconnected")
      setIsConnected(false)
    }

    const newChatHandler = (chat) => {
      setChats(chats => [chat, ...chats])
    }

    const receivedMessageHandler = (message) => {
      console.log("recievedMessage: ", message)
      if (message.chat !== activeChat?._id) return
      setMessages(messages => [...messages, message])
    }

    const joinedChatHandler = (chat) => {
      setChats(chats => [...chats, chat])
    }

    const deletedChatHandler = (chat) => {
      if (chat._id === activeChat?._id) {
        console.log("active chat deleted turuuu")
        setActiveChat({})
        navigate("/chat")
      }
      setChats(chats => chats.filter(c => c._id !== chat._id))
    }

    const errorHandler = (err) => {
      console.log("socket error: ", err)
    }

    socket.on('connect', connectHandler)
    socket.on('disconnect', disconnectHandler)
    socket.on('newChat', newChatHandler)
    socket.on('recievedMessage', receivedMessageHandler)
    socket.on("joinedChat", joinedChatHandler)
    socket.on("deletedChat", deletedChatHandler)
    socket.on("error", errorHandler)

    return () => {
      socket.off('connect', connectHandler)
      socket.off('disconnect', disconnectHandler)
      socket.off('newChat', newChatHandler)
      socket.off('recievedMessage', receivedMessageHandler)
      socket.off('joinedChat', joinedChatHandler)
      socket.off('deletedChat', deletedChatHandler)
      socket.off('error', errorHandler)
    }
  }, [activeChat, socket, isConnected, setActiveChat, navigate])

  // fetch chats on initial render
  useEffect(() => {
    setLoading(true )
    console.log("fetching chats...")
    axiosInstance.get("/chat").then((res) => {
      setLoading(false)
      setChats(res.data.data)
    }).catch((err) => {
      setLoading(false)
      console.log("getChats() failed: ", err)
    })
  }, [])

  return (
    <ChatContext.Provider value={{ chats, loading, messages, setMessages, activeChat, setActiveChat, deleteChat, leaveChat }}>
      {children}
      {/* delete chat confirmation */}
      <AlertDialog open={showDeleteChatDialog} onOpenChange={setShowDeleteChatDialog} >
        <AlertDialogContent>
          <AlertDialogHeader>
            Are you sure you want to delete this chat?
          </AlertDialogHeader>
          <div className="w-full flex justify-end items-center gap-3">
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction asChild>
              <Button onClick={() => {
                axiosInstance.delete(`/chat/${chatIdToDelete}`)
                  .then((res) => {
                    console.log("chat deleted: ", res.data)
                    setChats(chats => chats.filter(chat => chat._id !== chatIdToDelete))
                    setChatIdToDelete({}) // reset chatIdToDelete
                    setShowDeleteChatDialog(false)
                  }).catch((err) => {
                    console.log("deleteChat() failed: ", err)
                  })
              }} variant="destructive">
                Delete
              </Button>
            </AlertDialogAction>
          </div>

        </AlertDialogContent>
      </AlertDialog>
      {/* leave chat confirmation */}
      <AlertDialog open={showLeaveChatDialog} onOpenChange={setShowLeaveChatDialog} >
        <AlertDialogContent>
          <AlertDialogHeader>
            Are you sure you want to leave this chat?
          </AlertDialogHeader>
          <div className="w-full flex justify-end items-center gap-3">
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction asChild>
              <Button onClick={() => {
                axiosInstance.delete(`/chat/${chatIdToLeave}/leave`)
                  .then((res) => {
                    console.log("chat left: ", res.data)
                    setChats(chats => chats.filter(chat => chat._id !== chatIdToLeave))
                    setChatIdToLeave({})
                    navigate("/chat")
                    setShowLeaveChatDialog(false)
                  }).catch((err) => {
                    console.log("leaveChat() failed: ", err)
                  })
              }} variant="destructive">
                Leave
              </Button>
            </AlertDialogAction>
          </div>

        </AlertDialogContent>
      </AlertDialog>
    </ChatContext.Provider>
  );
}

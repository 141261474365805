"use client"

import { IoIosArrowBack } from 'react-icons/io'
import { IoEllipsisVertical } from 'react-icons/io5'
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar'
import { getChatMetaDataObject, getInitials } from '../utils'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from './ui/menubar'
import { useAuth } from '../context/auth'
import {Link} from 'react-router-dom'
import { useChat } from '../context/chats'

function ChatHeader({ chat }) {

  const { user } = useAuth()
  const { deleteChat, leaveChat } = useChat()

  function handleShare() {
    if (navigator.share)
      navigator.share({
        title: 'Chat App by dubeytech',
        text: 'Join my chat',
        url: `${window.location.origin}/chat/${chat._id}/invite`
      })
    else {
      alert(`share not supported. copy invite link:  ${window.location.origin}/chat/${chat._id}/invite `)
    }
  }

  return (
    <header className='h-14 py-2 flex items-center justify-between container'>
      <div className="flex items-center">
        <Link to='/chat' className='hover:bg-muted flex items-center justify-center p-1 rounded-[36px]'>
          <IoIosArrowBack className='text-xl' />
          <Avatar className="h-8 w-8" >
            <AvatarImage src={getChatMetaDataObject(chat, user)?.image} />
            <AvatarFallback>
              {getChatMetaDataObject(chat, user)?.name &&
                getInitials(getChatMetaDataObject(chat, user)?.name)}
            </AvatarFallback>
          </Avatar>
        </Link>
        <h2 className='font-bold ml-2'>{getChatMetaDataObject(chat, user)?.name}</h2>
      </div>
      <div className='flex items-center gap-4'>
        <Menubar>
          <MenubarMenu>
            <MenubarTrigger className='p-1 rounded-full hover:bg-muted'>
              <IoEllipsisVertical className="text-xl" />
            </MenubarTrigger>
            <MenubarContent align="end">
              <MenubarItem>
                Info
              </MenubarItem>
              {
                chat?.type !== "direct" &&
                <>
                  <MenubarItem
                    onClick={handleShare}>
                    Invite
                  </MenubarItem>
                </>
              }
              {
                chat?.admin === user?._id
                  ?
                  <MenubarItem
                    className="text-red-500 hover:bg-red-500/25 focus:text-red-500 focus:bg-red-500/25"
                    onClick={() => deleteChat(chat._id)}>
                    Delete
                  </MenubarItem>
                  :
                  <MenubarItem
                    onClick={() => leaveChat(chat._id)}
                    className="text-red-500 hover:bg-red-500/25 focus:text-red-500 focus:bg-red-500/25">
                    Leave
                  </MenubarItem>
              }
            </MenubarContent>
          </MenubarMenu>
        </Menubar>
      </div>
    </header>
  )
}

function ChatsListHeader() {
  const { user, logout } = useAuth()
  return (
    <header className='h-14 py-2 flex items-center justify-between container'>
      <h1 className='text-lg font-bold'>chat</h1>
      <div className='flex items-center gap-4'>
        <Menubar>
          <MenubarMenu>
            <MenubarTrigger>
              <Avatar >
                <AvatarImage src={user?.image} />
                <AvatarFallback >{getInitials(user?.name)}</AvatarFallback>
              </Avatar>
            </MenubarTrigger>
            <MenubarContent align="end" >
              <MenubarItem>
                Settings
              </MenubarItem>
              <MenubarItem
                onClick={logout}
                className="text-red-500 hover:bg-red-500/25 focus:text-red-500 focus:bg-red-500/25"
              >
                Logout
              </MenubarItem>
            </MenubarContent>
          </MenubarMenu>
        </Menubar>

      </div>
    </header>
  )
}

export default function Header({ variant, ...props }) {

  switch (variant) {
    case "chat":
      return <ChatHeader {...props} />
    default:
      return <ChatsListHeader {...props} />
  }

}


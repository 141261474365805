// write a simple react app that uses the pages/auth page

import { Navigate, Route, Routes } from "react-router-dom";
import Auth from "./pages/auth";
import Chats from "./pages/Chats";
import Chat from "./pages/Chat";
import ChatInvite from "./pages/ChatInvite"
import { useAuth } from "./context/auth";

export default function App() {
  const { user, token } = useAuth()
  const isAuthenticated = !!(user?._id && token)
 
  return (
    <Routes>
      {/*  root route  */}
      <Route path="/"
        element={
          isAuthenticated
            ? <Navigate to="/chat" />
            : <Navigate to="/auth" />
        } />
      {/* Authenticate user */}
      <Route exact path="/auth"
        element={
          isAuthenticated
            ? <Navigate to="/chat" />
            : <Auth />
        } />
      {/* Chats */}
      <Route path="/chat"
        element={
          isAuthenticated
            ? <Chats />
            : <Navigate to="/auth" />
        }
      />
      {/* chats/:id */}
      <Route path="/chat/:chatId"
        element={
          isAuthenticated
            ? <Chat />
            : <Navigate to="/auth" />
        }
      />
      {/* chats/:id/invite */}
      <Route path="/chat/:chatId/invite"
        element={
          isAuthenticated
            ? <ChatInvite />
            : <Navigate to="/auth" />
        }
      />
    </Routes>

  )
}
